import React, { useEffect, useState } from 'react';
import { Box, styled, TableContainer, Typography, Switch, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, CustomTable } from '../../components/CustomTable';
import { Location } from '../../models';
import { EmergencyInfo } from '../../models/emergency';
import { theme } from '../../styles/theme';
import { useEmergencyHook } from './store';
import { DEFAULT_ID, EmergencyKeys, StatusEmergencyTeam } from './type';
import { CircularLoading } from '../Users/Group/components';
import { combineMemberInMultipleLocation } from './utils';
import LocationFilter from './components/LocationFilter';
import ClearIcon from '@mui/icons-material/Clear';

const PresentTeam: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ locationList, emergencyList }, { getLocations, getEmergencyTeams }] = useEmergencyHook();

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [emergencyMembers, setEmergencyMembers] = useState<EmergencyInfo[]>([]);
  const [showAllMembers, setShowAllMembers] = useState<boolean>(false); // Switch standaard uit

  const columns: Column[] = [
    {
      dataKey: EmergencyKeys.NAME,
      fieldName: t('emergency_team.name')
    },
    {
      dataKey: EmergencyKeys.LOCATION,
      fieldName: t('emergency_team.registered_at_location')
    },
    {
      dataKey: EmergencyKeys.LAST,
      fieldName: t('emergency_team.last_scanned_door')
    }
  ];

  const sortListByStatus = (emergencyMembers: EmergencyInfo[]) => {
    const availableTeam = emergencyMembers.filter(team =>
      showAllMembers ? true : team.status === StatusEmergencyTeam.ON
    );
    return availableTeam;
  };

  const handleLocationChange = (locations: Location[]) => {
    setSelectedLocations(locations);
    if (locations.length === 0 || locations.some(location => location.id === DEFAULT_ID)) {
      setEmergencyMembers(combineMemberInMultipleLocation(emergencyList));
    } else {
      const filteredMembers = emergencyList.filter(member =>
        locations.some(location => location.location === member.location)
      );
      setEmergencyMembers(filteredMembers);
    }
  };

  const handleClearFilters = () => {
    setSelectedLocations([]); // Reset de geselecteerde locaties
    setEmergencyMembers(combineMemberInMultipleLocation(emergencyList)); // Toon alle noodteamleden, inclusief testdata
  };

  const initData = async () => {
    setLoading(true);
    await Promise.all([getLocations(), getEmergencyTeams()]);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    // Voeg de testdata toe zodra locationList niet leeg is
    if (locationList.length > 0 && emergencyMembers.length === 0) {
      const testData: EmergencyInfo[] = Array.from({ length: 20 }, (_, index) => {
        const randomLocation = locationList[Math.floor(Math.random() * locationList.length)];

        return {
          id: `id-${index + 1}`,
          name: `Member ${String.fromCharCode(65 + (index % 26))}`, // Genereert namen als Member A, Member B, ...
          lastScannedDoor: `Door ${index + 1}`,
          location: randomLocation.location,
          status: Math.random() > 0.5 ? StatusEmergencyTeam.ON : StatusEmergencyTeam.OFF,
          locationId: String(randomLocation.id) // Converteer locationId naar een string
        };
      });

      setEmergencyMembers(testData);
    }
  }, [locationList]);

  const isFilterApplied = selectedLocations.length > 0 || !showAllMembers;

  return (
    <Wrapper>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <HeaderWrapper>
            <Typography variant="subtitle4" color="grey.100">
              {t('emergency_team.location')}
            </Typography>

            <LocationFilter
              locations={locationList}
              selectedLocations={selectedLocations}
              onChange={handleLocationChange}
            />

            <SwitchWrapper>
              <CustomSwitch checked={showAllMembers} onChange={() => setShowAllMembers(!showAllMembers)} />
              <CustomTypography variant="body2">{t('emergency_team.all_members')}</CustomTypography>
            </SwitchWrapper>

            {isFilterApplied && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClearFilters}
                startIcon={<ClearIcon />}
                sx={{ marginLeft: '16px', marginRight: 'auto' }}>
                {t('door.clear_filters')}
              </Button>
            )}
          </HeaderWrapper>
          <EmergencyTeamContainer>
            <CustomTable columns={columns} dataList={sortListByStatus(emergencyMembers)} />
          </EmergencyTeamContainer>
        </>
      )}
    </Wrapper>
  );
};

export default PresentTeam;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.common.white};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${theme.spacing(3)} ${theme.spacing(3)} 0;
  width: 100%;
`;

const EmergencyTeamContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
  margin-top: ${theme.spacing(2)};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
`;

const SwitchWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing(2)};
`;

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  textAlign: 'left',
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[100]
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '40px',
  height: '20px',
  padding: '0px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSwitch-switchBase': {
    padding: '3px',
    top: '50%',
    transform: 'translateY(-50%)',
    '&.Mui-checked': {
      transform: 'translate(20px, -50%)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        backgroundColor: '#96C11F',
        opacity: 1
      }
    }
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#ACB8BD',
    height: '20px',
    borderRadius: '10px',
    opacity: 1
  },
  '& .MuiSwitch-thumb': {
    width: '14px',
    height: '14px',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    opacity: '1',
    boxShadow: 'none'
  }
}));
